<template>
  <main :style="{ background: 'url(' + this.fondo + ') #000' }">
    <vs-row>
      <vs-col w="12" class="not-padding">
        <grid-layout
          :layout.sync="tilesEscritorio"
          :row-height="100"
          :is-draggable="true"
          :is-resizable="false"
          :is-mirrored="false"
          :vertical-compact="false"
          :use-css-transforms="false"
          :responsive="true"
          :prevent-collision="true"
          :maxRows="5"
          dragAllowFrom="button"
        >
          <grid-item
            v-for="item in tilesEscritorio"
            :x="item.x"
            :y="item.y"
            :i="item.i"
            :key="item.i"
            :w="1"
            :minW="100"
            :minH="100"
            :h="1"
            :preserveAspectRatio="true"
            @moved="movedEvent(item.x, item.y, item.pktile)"
          >
            <div
              class="desktop-btn"
              @mouseover="trashVer = item.i"
              @mouseleave="trashVer = null"
            >
              <vs-tooltip
                class="desktop-btn-borrar-acceso"
                v-if="trashVer === item.i"
              >
                <vs-button flat icon @click="shortCutDelete(item.pktile)">
                  <i class="bx bx-trash"></i>
                </vs-button>
                <template #tooltip> Borrar acceso directo </template>
              </vs-tooltip>
              <div
                :style="item.estilo.fondo"
                class="vs-button vs-button--null vs-button--size-null vs-button--dark vs-button--shadow desktop-btn-item"
                @click.prevent="abrirVentana(item)"
              >
                <div class="vs-button__content">
                  <!-- <i class="bx bxs-phone-call"></i>  -->
                  <i class="mdi" :class="item.icon"></i> {{ item.nombre }}
                </div>
              </div>
            </div>
          </grid-item>
        </grid-layout>
      </vs-col>
      <vs-col w="12" class="escritorio-barra not-padding">
        <div class="barra-menu">
          <vs-button
            square
            icon
            flat
            class="btn-barra btn-menu"
            :active="barra.menu"
            @click="abrirMenu('menu')"
          >
            <i class="bx bx-menu"></i>
          </vs-button>
          <div class="barra-contenedor-menu" :class="{ open: barra.menu }">
            <perfect-scrollbar class="ps-menu">
              <div class="barra-btn-item" @click="ticketDialog = true">
                <div class="barra-btn-item-ico">
                  <div
                    class="barra-btn-item-color"
                    style="background-color: #994b9b"
                  ></div>
                  <i class="bx bx-support"></i>
                </div>
                <div class="barra-btn-item-text">Tickets</div>
              </div>
              <div
                class="barra-btn-item"
                v-for="(tile, index) in tiles"
                :key="index"
                @click.prevent="abrirVentana(tile)"
              >
                <vs-tooltip
                  class="barra-btn-acceso-directo"
                  v-if="!tile.pktile"
                >
                  <vs-button flat icon @click.prevent="shortCut(tile)">
                    <i class="bx bx-link-external"></i>
                  </vs-button>
                  <template #tooltip> Crear acceso directo </template>
                </vs-tooltip>
                <div class="barra-btn-item-ico" :title="tile.nombre">
                  <div
                    class="barra-btn-item-color"
                    :style="tile.estilo.fondo"
                  ></div>
                  <!-- <i class="bx bx-support"></i> -->
                  <i class="mdi" :class="tile.icon"></i>
                </div>
                <div class="barra-btn-item-text">{{ tile.nombre }}</div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="barra-tareas">
            <template v-for="(ventana, i) in ventanas">
              <div
                v-bind:key="i"
                @click="minimizarVentana(ventana)"
                v-if="!ventana.cerrada"
                text
                :class="{ activa: ventana.activa }"
                class="btn-tareas-ventanas"
              >
                <i class="mdi" :class="ventana.icono"></i>
                <p>{{ ventana.nombre }}</p>
                <div class="btn-tareas-borde" :style="ventana.color"></div>
              </div>
            </template>
          </div>
        </div>

        <div class="barra-default">
          <vs-button
            square
            icon
            flat
            dark
            class="btn-barra"
            @click="ticketDialog = true"
          >
            <i class="bx bx-support"></i>
          </vs-button>
          <!-- <vs-button
            square
            icon
            flat
            dark
            class="btn-barra"
            @click="abrirMenu('notificaciones')"
            :active="barra.notificaciones"
          >
            <i class="bx bxs-bell"></i>
          </vs-button>
          <div
            class="barra-contenedor-notificaciones"
            :class="{ open: barra.notificaciones }"
          >
            <perfect-scrollbar class="ps-notificaciones">
              <div
                class="barra-notificacion-item"
                v-for="(item, index) in notificaciones"
                :key="index"
              >
                <div class="barra-notificacion-titulo">
                  <div
                    class="barra-notificacion-icono"
                    :class="{ nuevos: !item.leido }"
                  >
                    <i class="mdi" :class="item.icono"></i>
                  </div>
                  <h1>{{ item.sistema }}</h1>
                </div>
                <div
                  class="barra-notificacion-texto"
                  v-for="(value, i) in item.notificaciones"
                  @mouseleave="leerNotificacion(value)"
                  :key="i"
                >
                  <h1>{{ value.titulo }}</h1>
                  <p v-html="value.texto"></p>
                </div>
              </div>
            </perfect-scrollbar>
          </div> -->
          <vs-avatar
            circle
            size="32"
            class="barra-avatar"
            @click="abrirMenu('perfil')"
          >
            <img
              :src="
                user.imagen
                  ? user.imagen
                  : require('@/assets/img/profile-placeholder.png')
              "
              alt="avatar"
            />
          </vs-avatar>
          <div class="barra-contenedor-perfil" :class="{ open: barra.perfil }">
            <div class="barra-perfil-menu">
              <h1>{{ user.nombre }}</h1>
              <ul class="botonera-menu-avatar">
                <li @click="perfilDialog = true">Mis datos</li>
                <li @click="contraseniaDialog = true">Cambiar contraseña</li>
                <li @click="cerrarSesion">Cerrar sesión</li>
              </ul>
            </div>

            <div class="barra-perfil-imagen">
              <vs-tooltip>
                <img
                  :src="
                    user.imagen
                      ? user.imagen
                      : require('@/assets/img/profile-placeholder.png')
                  "
                  alt="Foto de perfil"
                  @click="$refs.archivoImagenPerfil.click()"
                  style="cursor: pointer"
                />
                <input
                  type="file"
                  ref="archivoImagenPerfil"
                  @change="subirArchivo($event)"
                />
                <template #tooltip> Cambiar imágen </template>
              </vs-tooltip>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <template v-for="(ventana, i) in ventanas">
      <win
        :windowId="ventana.windowId"
        :active="true"
        :title="ventana.nombre"
        :key="i"
        :width="ventana.width"
        :height="ventana.height"
        :ventana="ventana"
        :ventanas.sync="ventanas"
        :class="{ 'ventana-oculta': !ventana.isOpen }"
        @cerrarVentana="cerrarVentana($event)"
        @focusChange="focusChange(ventana.windowId)"
        @minimizarVentana="minimizarVentana($event)"
        v-if="!ventana.cerrada"
      >
        <div
          style="
            width: 100%;
            height: calc(100% - 40px);
            box-sizing: border-box;
            border-style: none;
          "
          @click="focusChange(ventana.windowId)"
        >
          <iframe
            @click="focusChange(ventana.windowId)"
            allow="camera;microphone"
            :style="{ 'pointer-events': ventana.activa ? '' : 'none' }"
            :src="ventana.url"
            style="
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              border-style: none;
              border-radius: 0% 0% 26px 0%;
            "
          />
        </div>
      </win>
    </template>
    <div>
      <contraseniaComponent
        :active="contraseniaDialog"
        @cerrarDialog="cerrarDialog"
      ></contraseniaComponent>
      <perfilComponent
        :active="perfilDialog"
        :user="user"
        :darkModeProp="darkModeProp"
        @cerrarDialog="cerrarDialog"
        @traerUsuario="traerUsuario"
      ></perfilComponent>
      <ticketsComponent
        :active="ticketDialog"
        :user="user"
        @cerrarDialog="cerrarDialog"
      ></ticketsComponent>
    </div>
  </main>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import win from "@/components/windowComponent";
import contraseniaComponent from "@/components/contraseniaComponent";
import perfilComponent from "@/components/perfilComponent";
import ticketsComponent from "@/components/ticketsComponent";

export default {
  data() {
    return {
      barra: {
        menu: false,
        perfil: false,
        notificaciones: false,
      },
      user: {},
      fondo: require("@/assets/fondos/escritorio.png"),
      logoSistema: require("@/assets/logo.png"),
      tiles: [],
      trashVer: 0,
      notificaciones: [],
      ventanas: [],
      moviendo: false,
      contraseniaDialog: false,
      perfilDialog: false,
      ticketDialog: false,
      darkModeProp: false,
      imagenPerfil: { fileNombre: "", adjunto: [] },
    };
  },
  components: {
    GridLayout,
    GridItem,
    win,
    contraseniaComponent,
    perfilComponent,
    ticketsComponent,
  },
  async created() {
    Notification.requestPermission().then(function (result) {
      console.log(result);
    });
    await this.traerTodo();
    await this.traerUsuario();
  },
  sockets: {
    connect: function () {
      this.$socket.emit(
        "login",
        { cuit: this.user.cuit, oficina: this.user.oficina },
        function (data) {
          this.user.socket = data;
        }.bind(this)
      );
    },
    notificado: function (data) {
      this.$vs.notification({
        text: data.descripcion,
      });
      this.traerNotificaciones();
    },
  },
  methods: {
    abrirMenu(data) {
      const valorAnterior = this.barra[data];
      this.cerrarMenus();
      if (!valorAnterior) this.barra[data] = true;
    },
    cerrarMenus() {
      Object.keys(this.barra).forEach((propiedad) => {
        this.barra[propiedad] = false;
      });
    },
    traerTodo() {
      return this.$axios
        .post(`${this.$apiEscritorio}traerSistemas`, {})
        .then(
          function (response) {
            this.tiles = [];
            response.data.map((datos) => {
              let url = this.$frontend + datos.url + "/";
              this.tiles.push({
                i: datos.pksistema,
                nombre: datos.sistema,
                url: url,
                x: datos.x,
                y: datos.y,
                pktile: datos.pktile,
                w: 1,
                h: 1,
                icon: datos.icono,
                estilo: {
                  fondo: { "background-color": datos.color },
                  color: datos.color,
                },
              });
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            this.msg = error;
          }.bind(this)
        );
    },
    leerNotificacion(item) {
      if (!item.leido) {
        this.$socket.emit(
          "leer-notificacion",
          {
            pknotificacion: item.pknotificacion,
            token: localStorage.getItem("token"),
          },
          function (data) {
            if (data) this.traerNotificaciones();
          }.bind(this)
        );
      }
    },
    traerNotificaciones() {
      return true;
      // this.$socket.emit(
      //   "traer-notificaciones",
      //   { dni: this.user.dni, token: localStorage.getItem("token") },
      //   function (data) {
      //     this.notificaciones = data;
      //     data.map((v) => {
      //       if (v.leido == 0)
      //         new Notification(v.notificaciones[0].titulo, {
      //           body: v.notificaciones[0].texto,
      //           icon: this.logoSistema,
      //         });
      //     });
      //   }.bind(this)
      // );
    },
    subirArchivo(event) {
      const archivo = event.target.files;

      if (archivo && archivo.length > 0) {
        const blob = archivo[0];
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          this.$axios
            .post(`${this.$apiEscritorio}cambiarImagenPerfil`, {
              imagen: reader.result,
            })
            .then(
              function (response) {
                if (response.data.estado) {
                  this.user.imagen = reader.result;
                  this.$forceUpdate();
                }
              }.bind(this)
            )

            .catch(() => {
              this.$vs.notification(
                {
                  title: "Error",
                  text: `Error al subir imágen, intente nuevamente.`,
                }.bind(this)
              );
            });
        };
      }
    },
    traerUsuario() {
      return this.$axios
        .post(`${this.$apiEscritorio}usuario`, {})
        .then(
          function (response) {
            if (response.data.dni != null) {
              this.$socket.emit(
                "login",
                { dni: response.data.dni, oficina: response.data.oficina },
                function (data) {
                  this.user.socket = data;
                }.bind(this)
              );
            }
            this.user.nombre = `${response.data.nombre} ${response.data.apellido}`;
            this.user.imagen = response.data.imagen;
            this.user.cuit = response.data.cuit;
            this.user.oficina = response.data.oficina;
            this.user.dm = Boolean(response.data.dm);
            this.user.fkoficina = response.data.fkoficina;
            this.user.datoscompletos = response.data;
            localStorage.setItem("dm", response.data.dm);
            localStorage.setItem("oficina", response.data.oficina);
            this.traerNotificaciones();
            document.body.classList.toggle("darken", response.data.dm);
            document.body.setAttribute(
              "vs-theme",
              !response.data.dm ? "" : "dark"
            );
            localStorage.setItem("dm", response.data.dm);

            this.darkModeProp = Boolean(response.data.dm);
          }.bind(this)
        )

        .catch(
          function (error) {
            this.msg = error;
          }.bind(this)
        );
    },
    abrirVentana(w) {
      if (!w.url) return;

      const t = localStorage.getItem("token");
      const dm = localStorage.getItem("dm");
      this.cerrarMenus();

      const nuevaVentana = {
        windowId: Date.now().toString(),
        nombre: w.nombre,
        url: `${w.url}?t=${t}&dm=${dm}&oficina=${this.user.fkoficina}`,
        icono: w.icon,
        height: "600px",
        width: "400px",
        isOpen: true,
        fullScreen: true,
        color: w.estilo.fondo,
        cerrada: false,
        activa: true,
      };

      this.ventanas = this.ventanas.concat(nuevaVentana);
    },
    cerrarVentana(e) {
      this.ventanas[
        this.ventanas.findIndex((v) => v.windowId == e)
      ].cerrada = true;
    },
    focusChange(id) {
      this.cerrarMenus();
      this.ventanas.map((val) => {
        if (val.windowId != id || !val.isOpen) {
          val.activa = false;
        } else {
          val.activa = true;
        }
      });
    },
    minimizarVentana(v) {
      if (v.isOpen && !v.activa) {
        this.focusChange(v.windowId);
      } else if (!v.isOpen && !v.activa) {
        v.isOpen = true;
        this.focusChange(v.windowId);
      } else {
        v.isOpen = false;
        v.activa = false;
      }
    },
    shortCut: function (t) {
      let pksistema = t.i;
      this.$axios
        .post(`${this.$apiEscritorio}addDesktop`, { pksistema: pksistema })
        .then(
          function (response) {
            if (response.data.estado) this.traerTodo();
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    shortCutDelete(pktile) {
      this.$axios
        .post(`${this.$apiEscritorio}removeDesktopIcon`, { pktile: pktile })
        .then(
          function (response) {
            if (!response.data.estado) {
              this.$vs.notification({
                title: "Error",
                text: `Error al borrar el icono, intente nuevamente.`,
              });
              document
                .querySelectorAll(".vs-tooltip")
                .forEach((el) => el.remove());
            } else {
              this.traerTodo();
            }
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    movedEvent(newX, newY, pktile) {
      this.moviendo = true;
      this.$axios
        .post(`${this.$apiEscritorio}changeDesktop`, {
          x: newX,
          y: newY,
          pktile: pktile,
        })
        .then(
          function (response) {
            if (!response.data.estado) {
              this.$vs.notification({
                title: "Error",
                text: `Error al cambiar el icono, intente nuevamente.`,
              });
            }
            this.moviendo = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.moviendo = false;
          }.bind(this)
        );
    },
    cerrarDialog(e) {
      this[e] = false;
    },
    cerrarSesion() {
      localStorage.setItem("token", "");
      this.$router.push({ path: "/" });
    },
  },
  computed: {
    tilesEscritorio: {
      get() {
        return this.tiles.filter(function (u) {
          return u.pktile;
        });
      },
      set() {
        return true;
      },
    },
  },
};
</script>

<style scope>
main {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.escritorio-barra {
  height: 45px;
  position: fixed !important;
  width: 100% !important;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  outline: 1px solid #303030;
  z-index: 10002;
  display: flex !important;
  justify-content: space-between !important;
}
.barra-default {
  display: flex;
  position: relative;
  align-items: center;
}
.barra-menu {
  display: flex;
}
.barra-avatar {
  margin: 6px;
  cursor: pointer;
}
.btn-barra {
  margin: 0 !important;
  width: 45px;
  height: 45px;
  background: transparent;
  color: #fff !important;
}
.barra-contenedor-menu {
  position: absolute;
  bottom: 45px;
  left: 0;
  max-width: 341px;
  max-height: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  /* border-top: 1px solid hsla(0, 0%, 100%, 0.4);
  border-right: 1px solid hsla(0, 0%, 100%, 0.4); */
  padding: 5px;
  display: none;
}

.barra-btn-item-text {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  line-height: 50px;
}

.barra-btn-acceso-directo {
  position: absolute;
  right: 0;
  top: 0;
}

.barra-btn-item {
  position: relative;
  height: 50px;
  margin-bottom: 5px;
  width: 330px;
  cursor: pointer;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  display: flex;
}

.barra-btn-item-ico {
  width: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.barra-btn-item:before {
  content: " ";
  width: 100%;
  height: 1px;
  top: -3px;
  background: #fff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(hsla(0, 0%, 100%, 0)),
    color-stop(50%, hsla(0, 0%, 100%, 0.6)),
    to(hsla(0, 0%, 100%, 0))
  );
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.6) 50%,
    hsla(0, 0%, 100%, 0)
  );
  left: 0;
  position: absolute;
}

.barra-btn-item:hover {
  background-color: rgba(255, 255, 255, 25%);
}

.barra-btn-acceso-directo button {
  width: 24px;
  height: 24px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 !important;
  margin: 0;
}

.barra-btn-acceso-directo button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.barra-btn-item-color {
  position: absolute;
  height: 50px;
  width: 5px;
  left: 0;
  top: 0;
  background-color: #fff;
}
.ps-menu {
  max-height: 390px;
}
.ps-notificaciones {
  max-height: 100%;
}

.barra-contenedor-perfil {
  position: absolute;
  bottom: 45px;
  width: 350px;
  right: 0;
  height: 170px;
  overflow: hidden;
  color: #fff;
  display: none;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);

  justify-content: space-between;
}

.barra-perfil-menu {
  padding: 15px;
  z-index: 1;
}

.barra-perfil-imagen {
  max-width: 150px;
  min-width: 150px;
  height: 100%;
  z-index: 1;
}

.barra-perfil-imagen img {
  max-width: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.botonera-menu-avatar {
  font-size: 14px;
  font-weight: 300;
  list-style: none;
  padding: 0;
  margin: 0;
}

.barra-perfil-menu h1 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0;
}

.botonera-menu-avatar li {
  cursor: pointer;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.botonera-menu-avatar li:hover {
  color: rgb(var(--vs-primary));
}

.barra-perfil-imagen .vs-tooltip-content {
  height: 100%;
  object-fit: cover;
}

.open {
  display: flex !important;
}
.desktop-btn {
  width: 100px;
  height: 100px;
  position: relative;
}

.desktop-btn .desktop-btn-item {
  width: 100%;
  height: 100%;
  margin: 0;
}

.desktop-btn .desktop-btn-borrar-acceso {
  position: absolute;
  z-index: 9;
  right: 0;
  top: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.desktop-btn .desktop-btn-borrar-acceso button {
  margin: 0;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
}

.desktop-btn .desktop-btn-item .vs-button__content {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  height: 100%;
  justify-content: space-evenly;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
.vue-grid-item {
  max-width: 100px !important;
}

.desktop-btn .desktop-btn-item .vs-button__content i {
  font-size: 30px;
}

.desktop-btn:hover .desktop-btn-borrar-acceso {
  -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px);
}
.vue-grid-item.vue-grid-placeholder {
  display: none;
}
.vue-grid-layout {
  height: 89vh !important;
  overflow: hidden;
}
.barra-contenedor-notificaciones {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  color: #fff;
  height: calc(100% - 65px);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  /* border-left: 1px solid hsla(0, 0%, 100%, 0.4); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  display: none;
}

.barra-notificacion-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.barra-notificacion-titulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 30px;
}

.barra-notificacion-icono {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--vs-primary));
  font-size: 16px;
  position: relative;
  border-radius: 5px;
}
.barra-notificacion-icono.nuevos::after {
  content: " ";
  width: 5px;
  height: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgb(var(--vs-warn));
  border-radius: 50%;
}

.barra-notificacion-titulo h1 {
  margin-left: 10px;
  font-size: 16px;
}

.barra-notificacion-texto h1 {
  font-size: 14px;
  font-weight: 500;
}

.barra-notificacion-texto p {
  font-weight: 200;
  font-size: 12px;
}
.barra-notificacion-texto p a {
  color: #fff;
}

.barra-notificacion-titulo::before {
  content: " ";
  position: absolute;
  width: calc(100% - 25px);
  left: 25px;
  height: 1px;
  bottom: 0;
  background-color: rgb(255 255 255 / 40%);
}

.barra-notificacion-texto {
  width: calc(100% - 10px);
  cursor: pointer;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  padding-left: 10px;
  position: relative;
}

.barra-notificacion-texto:hover {
  background-color: rgb(255 255 255 / 40%);
}
.vs-tooltip,
.vs-notification-parent {
  z-index: 9999999999999 !important;
}
.vs-notification-parent {
  margin-bottom: 45px;
}
.ventana-oculta {
  display: none !important;
}
.barra-tareas {
  position: relative;
  display: flex;
  width: calc(100% - 45px);
}

.btn-tareas-ventanas {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  color: #c1c1c1;
  position: relative;
  background-color: rgb(255 255 255 / 15%);
  cursor: pointer;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  opacity: 0.7;
  margin-right: 5px;
}

.btn-tareas-ventanas i {
  margin-right: 10px;
}
.btn-tareas-ventanas p {
  margin: 0;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}
.btn-tareas-borde {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
}
.btn-tareas-ventanas:hover {
  background-color: rgb(255 255 255 / 25%);
  color: #fff;
}

.btn-tareas-ventanas.activa {
  opacity: 1;
  color: #fff;
}
.escritorio-barra::before,
.barra-contenedor-menu::before,
.barra-contenedor-perfil::before,
.barra-contenedor-notificaciones::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
</style>
