<template>
  <main :style="{ background: 'url(' + this.fondo + ') #000' }">
    <vs-row justify="center" align="center">
      <vs-col w="3">
        <vs-card class="login-card">
          <template #title>
            <h1 class="login-titulo">Login</h1>
          </template>
          <template #text>
            <vs-input
              v-model="usuario"
              placeholder="Usuario"
              class="login-input"
            >
              <template #icon>
                <i class="bx bx-user"></i>
              </template>
            </vs-input>

            <vs-input
              type="password"
              v-model="contrasenia"
              placeholder="Contraseña"
              class="login-input"
              @keyup.enter="login()"
            >
              <template #icon>
                <i class="bx bx-lock-open-alt"></i>
              </template>
            </vs-input>
            <!-- <p class="login-olvide">Olvide mi contraseña</p> -->
            <vs-button block :loading="loading" @click="login()">
              Login
            </vs-button>
          </template>
        </vs-card>
      </vs-col>
    </vs-row>
  </main>
</template>

<script>
export default {
  data() {
    return {
      usuario: "",
      contrasenia: "",
      loading: false,
      fondo: require("@/assets/fondos/login.png"),
    };
  },
  methods: {
    login: function () {
      this.loading = !this.loading;
      this.$axios
        .post(this.$api + "loguear", {
          usuario: this.usuario,
          password: this.contrasenia,
        })
        .then(
          function (response) {
            if (response.data.estado) {
              localStorage.setItem("token", response.data.token);

              this.$router.push({ path: "/escritorio" });
              this.usuario = "";
              this.contra = "";
            } else {
              this.$vs.notification({
                title: "Info",
                text: response.data.respuesta,
              });
              this.snackbar = true;
              this.loading = !this.loading;
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            this.$vs.notification({
              color: "danger",
              title: "Error",
              text: error,
            });
          }.bind(this)
        );
    },
  },
};
</script>

<style scope>
main {
  display: flex;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.login-titulo {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
}
.login-card .vs-card {
  min-width: 100%;
  padding: 10px 20px;
}
.login-input {
  margin-bottom: 15px;
}
.login-olvide {
  text-align: right;
  cursor: pointer;
}
.login-olvide:hover {
  text-decoration: underline;
}
</style>
