import { render, staticRenderFns } from "./windowComponent.vue?vue&type=template&id=12ba2cfa&scoped=true"
import script from "./windowComponent.vue?vue&type=script&lang=js"
export * from "./windowComponent.vue?vue&type=script&lang=js"
import style0 from "./windowComponent.vue?vue&type=style&index=0&id=12ba2cfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ba2cfa",
  null
  
)

export default component.exports