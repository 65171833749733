<template>
  <vs-dialog
    blur
    v-model="activeLocal"
    @close="$emit('cerrarDialog', 'contraseniaDialog')"
    prevent-close
    :loading="loading"
  >
    <template #header>
      <h4>Cambiar contraseña</h4>
    </template>

    <form ref="form">
      <vs-col>
        <vs-input
          type="password"
          v-model="actual"
          label-placeholder="Contraseña actual"
          :rules="JSON.stringify([!!actual || 'Contraseña actual requerida'])"
          required
        >
        </vs-input>
      </vs-col>
      <vs-col>
        <vs-input
          type="password"
          v-model="nueva"
          label-placeholder="Nueva contraseña"
          :rules="
            JSON.stringify([
              !!nueva || 'Contraseña nueva, requerida',
              this.nueva === this.nueva2 || 'Las contraseñas no coinciden',
            ])
          "
          required
        >
        </vs-input>
      </vs-col>
      <vs-col>
        <vs-input
          type="password"
          v-model="nueva2"
          label-placeholder="Repetir nueva contraseña"
          :rules="
            JSON.stringify([
              !!nueva2 || 'Contraseña nueva, requerida',
              this.nueva === this.nueva2 || 'Las contraseñas no coinciden',
            ])
          "
          required
        >
        </vs-input>
      </vs-col>
    </form>

    <template #footer>
      <vs-col w="12">
        <vs-button class="not-margin" block @click="guardarContrasenia">
          Guardar
        </vs-button>
      </vs-col>
    </template>
  </vs-dialog>
</template>

<script>
export default {
  data() {
    return {
      actual: "",
      nueva: "",
      nueva2: "",
      loading: false,
    };
  },
  props: {
    active: Boolean,
  },
  methods: {
    guardarContrasenia() {
      if (this.$utils.validarCampos(this.$refs["form"])) {
        this.loading = true;

        this.$axios
          .post(`${this.$apiEscritorio}cambiarPassword`, {
            actual: this.actual,
            nueva1: this.nueva,
            nueva2: this.nueva2,
          })
          .then(
            function (response) {
              this.loading = false;
              if (!response.data.estado) {
                this.$emit("notificacion", response.data.mensaje);
                this.$vs.notification({
                  title: "Error",
                  text: response.data.mensaje,
                });
              } else {
                this.$emit("cerrarDialog", "contraseniaDialog");
                this.actual = "";
                this.nueva = "";
                this.nueva2 = "";
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
            }.bind(this)
          );
      }
    },
  },
  computed: {
    activeLocal: {
      get() {
        return this.active;
      },
      set() {
        return true;
      },
    },
  },
};
</script>
