import moment from "moment";
import "moment/locale/es";

function validarCampos(formulario) {
  let respuesta = true;

  formulario.querySelectorAll(".vs-input__message").forEach((e) => e.remove());

  formulario
    .querySelectorAll(
      "form .vs-input-parent, form .vs-select-content, form textarea.vs-input"
    )
    .forEach((elemento) => {
      const rules = elemento.getAttribute("rules");
      const required = elemento.getAttribute("required");
      const tagName = elemento.tagName.toLowerCase();

      if (required) {
        const reglas = verificarReglas(JSON.parse(rules));

        if (reglas !== true) {
          respuesta = false;
          const div = document.createElement("div");
          div.className = "vs-input__message vs-input__message--danger";
          div.appendChild(document.createTextNode(reglas));

          tagName === "textarea"
            ? elemento.insertAdjacentElement("afterend", div)
            : elemento.appendChild(div);
        }
      }
    });

  return respuesta;
}

function verificarReglas(arr) {
  const primerString = arr.find((elemento) => typeof elemento === "string");

  return primerString !== undefined ? primerString : !arr.includes(false);
}

function formatDate(dateString) {
  const date = moment(dateString);
  const formattedDate = date.format("DD MMMM YYYY, h:mm a");
  return formattedDate;
}

export { validarCampos, formatDate };
