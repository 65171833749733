<template>
  <vs-dialog
    blur
    v-model="activeLocal"
    @close="$emit('cerrarDialog', 'ticketDialog')"
    prevent-close
    not-padding
    class="dialog-ticket"
  >
    <vs-card>
      <template #title>
        <vs-navbar class="navbar-titulo">
          <template #left> <h2>Tickets</h2> </template>
          <vs-input
            v-model="search"
            border
            placeholder="Buscar"
            class="ticket-buscador"
          />
          <template #right>
            <vs-button @click="dialogNuevo = true">Nuevo Ticket</vs-button>
          </template>
        </vs-navbar>
      </template>
      <template #text>
        <vs-table striped class="ticket-tabla">
          <template #thead>
            <vs-tr>
              <vs-th
                sort
                @click="tickets = $vs.sortData($event, tickets, 'pkticket')"
              >
                N° Ticket
              </vs-th>
              <vs-th
                sort
                @click="tickets = $vs.sortData($event, tickets, 'titulo')"
              >
                Titulo
              </vs-th>
              <vs-th> Descripcion </vs-th>
              <vs-th> Estado </vs-th>
              <vs-th> Opciones </vs-th>
            </vs-tr>
          </template>
          <template #tbody>
            <vs-tr
              :key="i"
              v-for="(tr, i) in $vs.getPage(
                $vs.getSearch(tickets, search),
                page,
                max
              )"
              :data="tr"
            >
              <vs-td>
                {{ tr.pkticket }}
              </vs-td>
              <vs-td>
                {{ tr.titulo }}
              </vs-td>
              <vs-td>
                {{ tr.descripcion }}
              </vs-td>
              <vs-td>
                <div class="etiqueta" :class="colorEstado(tr.pkestado)">
                  {{ tr.estado }}
                </div>
              </vs-td>
              <vs-td>
                <vs-tooltip>
                  <vs-button flat icon @click="verTicket(tr)">
                    <i class="bx bx-search-alt-2"></i>
                  </vs-button>
                  <template #tooltip>Ver</template>
                </vs-tooltip>
              </vs-td>
            </vs-tr>
          </template>
          <template #footer>
            <vs-pagination
              v-model="page"
              :length="$vs.getLength($vs.getSearch(tickets, search), max)"
            />
          </template>
        </vs-table>
      </template>
    </vs-card>
    <vs-dialog
      v-model="dialogNuevo"
      blur
      class="dialog-ticket-nuevo"
      :loading="loading"
    >
      <template #header>
        <h4 class="not-margin">Nuevo Ticket</h4>
      </template>

      <form ref="form-ticket" class="ticket-nuevo-form">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-input
              v-model="ticket.titulo"
              placeholder="Título"
              :rules="JSON.stringify([!!ticket.titulo || 'Requerido'])"
              required
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-select
              placeholder="Area"
              v-model="ticket.area"
              :rules="JSON.stringify([!!ticket.area || 'Requerido'])"
              required
              v-if="areas.length > 0"
            >
              <vs-option
                v-for="(area, index) in areas"
                :key="index"
                :value="area.pkarea"
                :label="area.area"
              >
                {{ area.area }}
              </vs-option>
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="12">
            <textarea
              class="vs-input"
              placeholder="Descripción"
              v-model="ticket.descripcion"
              :rules="JSON.stringify([!!ticket.descripcion || 'Requerido'])"
              required
            ></textarea>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-input
              v-model="ticket.fileNombre"
              placeholder="Seleccione un archivo"
              @click="$refs.archivoTicket.click()"
              readonly
            />
            <input
              type="file"
              ref="archivoTicket"
              @change="previewFile($event, ticket)"
            />
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-input
              v-model="ticket.link"
              placeholder="Link/Nombre del Sistema"
            />
          </vs-col>
        </vs-row>
      </form>
      <template #footer>
        <vs-col w="12">
          <vs-button class="not-margin" block @click="guardarTicket">
            Guardar
          </vs-button>
        </vs-col>
      </template>
    </vs-dialog>
    <vs-dialog
      scroll
      overflow-hidden
      full-screen
      auto-width
      v-model="dialogTicket"
      class="ticket-visualizador"
    >
      <template #header>
        <h2>Ticket #{{ ticketItemHistorial.pkticket }}</h2>
        <h4>{{ $utils.formatDate(ticketItemHistorial.fecha_creacion) }}</h4>
        <div class="ticket-header-botones">
          <vs-button
            v-if="ticketItemHistorial.adjunto"
            @click="verAdjunto(ticketItemHistorial)"
          >
            Adjunto
          </vs-button>
          <vs-tooltip
            v-if="
              ticketMovimientosHistorial.length == 0 &&
              ticketItemHistorial.fkestado != 3
            "
          >
            <vs-button
              @click="
                (ticketResponder = ticketItemHistorial),
                  (dialogResponder = true)
              "
            >
              <i class="bx bxs-send"></i>
            </vs-button>
            <template #tooltip> Responder </template>
          </vs-tooltip>
        </div>

        <p>
          <perfect-scrollbar class="ps-ticket">
            {{ ticketItemHistorial.descripcion }}
          </perfect-scrollbar>
        </p>
      </template>
      <div class="ticket-contenedor-respuestas">
        <perfect-scrollbar
          class="ps-ticket-contenedor"
          v-if="ticketMovimientosHistorial.length > 0"
        >
          <div
            class="ticket-respuesta-item"
            v-for="(item, index) in ticketMovimientosHistorial"
            :key="index"
          >
            <h3>{{ item.nombre }}</h3>
            <h5>{{ $utils.formatDate(item.fecha_creacion) }}</h5>
            <vs-tooltip
              class="ticket-respuesta-boton"
              left
              v-if="index == 0 && item.fkestado != 3"
            >
              <vs-button
                @click="(ticketResponder = item), (dialogResponder = true)"
              >
                <i class="bx bxs-send"></i>
              </vs-button>
              <template #tooltip> Responder </template>
            </vs-tooltip>

            <p>
              {{ item.descripcion }}
            </p>

            <vs-button
              v-if="item.adjunto != ''"
              @click="verAdjunto(item)"
              class="btn-adjunto-ticket-item"
            >
              Adjunto
            </vs-button>

            <span
              >Cambio el estado a
              <strong class="etiqueta" :class="colorEstado(item.fkestado)">{{
                item.estado
              }}</strong></span
            >
          </div>
        </perfect-scrollbar>
        <h1 v-else>No hay movimientos con este ticket</h1>
      </div>
    </vs-dialog>
    <vs-dialog
      v-model="dialogResponder"
      blur
      class="dialog-ticket-nuevo"
      :loading="loadingRespuesta"
    >
      <template #header>
        <h4 class="not-margin">Responder Ticket</h4>
      </template>

      <form ref="form-ticket-responder" class="ticket-nuevo-form">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="12">
            <textarea
              class="vs-input"
              placeholder="Descripción"
              v-model="ticketRespuesta.descripcion"
              :rules="
                JSON.stringify([!!ticketRespuesta.descripcion || 'Requerido'])
              "
              required
            ></textarea>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="12">
            <vs-input
              v-model="ticketRespuesta.fileNombre"
              placeholder="Seleccione un archivo"
              @click="$refs.archivoTicketRespuesta.click()"
              readonly
            />
            <input
              type="file"
              ref="archivoTicketRespuesta"
              @change="previewFile($event, ticketRespuesta)"
            />
          </vs-col>
        </vs-row>
      </form>
      <template #footer>
        <vs-col w="12">
          <vs-button class="not-margin" block @click="guardarTicketRespuesta">
            Responder
          </vs-button>
        </vs-col>
      </template>
    </vs-dialog>
  </vs-dialog>
</template>

<script>
export default {
  data() {
    return {
      usuario: this.user,
      tickets: [],
      search: "",
      page: 1,
      max: 5,
      ticket: {
        titulo: "",
        area: 0,
        link: "",
        fileNombre: "",
        file: [],
        descripcion: "",
      },
      dialogNuevo: false,
      areas: [{}],
      loading: false,
      dialogTicket: false,
      ticketItemHistorial: {},
      ticketMovimientosHistorial: [],
      dialogResponder: false,
      ticketRespuesta: {
        fileNombre: "",
        file: [],
        descripcion: "",
      },
      ticketResponder: {},
      loadingRespuesta: false,
    };
  },
  props: {
    user: Object,
    active: Boolean,
  },
  async mounted() {
    await this.traerTickets();
    await this.traerAreas();
  },
  methods: {
    traerTickets() {
      return this.$axios
        .post(`${this.$apiEscritorio}traerTickets`, {
          dni: this.usuario.dni,
          usuario: true,
        })
        .then(
          function (response) {
            this.tickets = response.data;
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    previewFile(event, objeto) {
      const archivo = event.target.files;

      if (archivo && archivo.length > 0) {
        objeto.fileNombre = archivo[0].name;

        const blob = archivo[0];

        objeto.file = blob;

        var reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onload = () => {
          objeto.file = reader.result;
        };
      }
    },
    traerAreas() {
      return this.$axios
        .post(`${this.$apiEscritorio}traerAreas`)
        .then(
          function (response) {
            this.areas = response.data;
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    colorEstado(item) {
      var colores = {
        1: "warning",
        2: "warning",
        3: "success",
        4: "danger",
        5: "dark",
        6: "warning",
      };
      return colores[item];
    },
    guardarTicket() {
      if (this.$utils.validarCampos(this.$refs["form-ticket"])) {
        this.loading = true;
        this.$axios
          .post(`${this.$apiEscritorio}insertarTicket`, {
            titulo: this.ticket.titulo,
            descripcion: this.ticket.descripcion,
            area: this.ticket.area,
            adjunto: this.ticket.file,
            linksistema: this.ticket.link,
          })
          .then(
            function () {
              this.traerTickets();
              this.loading = false;
              this.dialogNuevo = false;
              this.ticket = {
                titulo: "",
                area: 0,
                link: "",
                fileNombre: "",
                file: [],
                descripcion: "",
              };
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    guardarTicketRespuesta() {
      const pkticket = this.ticketResponder.pkticket
        ? this.ticketResponder.pkticket
        : this.ticketResponder.fkticket;
      if (this.$utils.validarCampos(this.$refs["form-ticket-responder"])) {
        this.loadingRespuesta = true;
        this.$axios
          .post(`${this.$apiEscritorio}cambiarEstadoTicket`, {
            estado: { pkestado: 1, estado: "Pendiente" },
            adjunto: this.ticketRespuesta.file,
            pkticket: pkticket,
            descripcion: this.ticketRespuesta.descripcion,
            fkusuario: this.ticketResponder.fkusuario,
          })
          .then(
            function () {
              this.traerTickets();
              this.loadingRespuesta = false;
              this.dialogResponder = false;
              this.dialogResponder = false;
            }.bind(this)
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    verTicket(item) {
      this.ticketItemHistorial = item;
      this.$axios
        .post(`${this.$apiEscritorio}historialTicket`, {
          pkticket: item.pkticket,
        })
        .then(
          function (response) {
            this.dialogTicket = true;
            this.ticketMovimientosHistorial = response.data;
          }.bind(this)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    verAdjunto(item) {
      const pkticket = item.pkticket ? item.pkticket : item.fkticket;
      this.$axios
        .post(`${this.$apiEscritorio}verAdjunto`, {
          nombre_archivo: item.adjunto,
          pkticket: pkticket,
        })
        .then(function (response) {
          var pdfWindow = window.open();
          pdfWindow.document.write(
            `<style>
                body {
                  margin: 0;
                  overflow: hidden;
                }
                iframe {
                  border: none;
                }
              </style>
              <iframe width='100%' height='100%' src='data:${response.data.tipoarchivo};base64,${response.data.archivo}'></iframe>`
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  computed: {
    activeLocal: {
      get() {
        return this.active;
      },
      set() {
        return true;
      },
    },
  },
};
</script>

<style>
.dialog-ticket {
  overflow: hidden !important;
}
.dialog-ticket .vs-dialog {
  min-width: 70% !important;
}
.dialog-ticket .vs-card {
  min-width: 100% !important;
}
.dialog-ticket .vs-card__text,
.dialog-ticket .vs-card__title {
  padding: 0 !important;
}
.ticket-tabla .vs-table__td:last-child {
  text-align: -webkit-center;
}
.ticket-tabla .vs-table__td:nth-child(3) {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ticket-tabla .vs-button {
  width: 30px;
  height: 30px;
  margin: 0;
}
.ticket-buscador {
  margin: 5px 0;
}
.ticket-buscador .vs-input-content {
  width: 400px;
}
.dialog-ticket-nuevo .vs-dialog {
  min-width: 800px !important;
}
.ticket-visualizador .vs-dialog__header {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  padding: 20px !important;
  position: relative;
}

.ticket-visualizador .vs-dialog__header h2,
.ticket-visualizador .vs-dialog__header h4,
.ticket-respuesta-item h3,
.ticket-respuesta-item h5 {
  margin: 0;
}

.ticket-visualizador .vs-dialog__header p {
  font-size: 14px;
  max-height: 45px;
  min-height: 45px;
  overflow: auto;
}

.ticket-visualizador .vs-dialog__content {
  padding: 10px 16px !important;
  max-height: calc(100vh - 210px) !important;
}
.ps-ticket {
  max-height: 45px;
}
.ps-ticket-contenedor {
  max-height: calc(100vh - 230px) !important;
}
.ticket-visualizador .vs-dialog__header .ticket-header-botones {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}
.ticket-respuesta-item {
  border: 1px solid rgba(var(--vs-text), 0.3) !important;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}
.ticket-respuesta-item p {
  font-size: 14px;
}

.ticket-respuesta-item .ticket-respuesta-boton {
  position: absolute;
  top: 10px;
  right: 10px;
}
.ticket-respuesta-boton-header {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.ticket-contenedor-respuestas h1 {
  text-align: center;
  opacity: 0.2;
}
.ticket-respuesta-item span {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-top: 16px;
}

.ticket-respuesta-item span strong {
  margin-left: 10px;
}
.btn-adjunto-ticket-item {
  position: absolute !important;
  right: 10px;
  bottom: 10px;
}
</style>
