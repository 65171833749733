var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-dialog',{attrs:{"blur":"","prevent-close":"","loading":_vm.loading},on:{"close":function($event){return _vm.$emit('cerrarDialog', 'contraseniaDialog')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("Cambiar contraseña")])]},proxy:true},{key:"footer",fn:function(){return [_c('vs-col',{attrs:{"w":"12"}},[_c('vs-button',{staticClass:"not-margin",attrs:{"block":""},on:{"click":_vm.guardarContrasenia}},[_vm._v(" Guardar ")])],1)]},proxy:true}]),model:{value:(_vm.activeLocal),callback:function ($$v) {_vm.activeLocal=$$v},expression:"activeLocal"}},[_c('form',{ref:"form"},[_c('vs-col',[_c('vs-input',{attrs:{"type":"password","label-placeholder":"Contraseña actual","rules":JSON.stringify([!!_vm.actual || 'Contraseña actual requerida']),"required":""},model:{value:(_vm.actual),callback:function ($$v) {_vm.actual=$$v},expression:"actual"}})],1),_c('vs-col',[_c('vs-input',{attrs:{"type":"password","label-placeholder":"Nueva contraseña","rules":JSON.stringify([
            !!_vm.nueva || 'Contraseña nueva, requerida',
            this.nueva === this.nueva2 || 'Las contraseñas no coinciden',
          ]),"required":""},model:{value:(_vm.nueva),callback:function ($$v) {_vm.nueva=$$v},expression:"nueva"}})],1),_c('vs-col',[_c('vs-input',{attrs:{"type":"password","label-placeholder":"Repetir nueva contraseña","rules":JSON.stringify([
            !!_vm.nueva2 || 'Contraseña nueva, requerida',
            this.nueva === this.nueva2 || 'Las contraseñas no coinciden',
          ]),"required":""},model:{value:(_vm.nueva2),callback:function ($$v) {_vm.nueva2=$$v},expression:"nueva2"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }