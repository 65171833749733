<template>
  <vs-dialog
    blur
    v-model="activeLocal"
    @close="$emit('cerrarDialog', 'perfilDialog')"
    prevent-close
    auto-width
    not-padding
  >
    <vs-card type="3" class="perfil-card">
      <template #title>
        <h3>{{ usuario.nombre }}</h3>
      </template>
      <template #img>
        <img
          :src="
            usuario.imagen
              ? usuario.imagen
              : require('@/assets/img/profile-placeholder.png')
          "
          alt="Foto de perfil"
        />
      </template>
      <template #text>
        <vs-col>
          <vs-input
            label="Nombre"
            v-model="usuario.datoscompletos.nombre"
            placeholder="Nombre"
            readonly
          />
        </vs-col>
        <vs-col>
          <vs-input
            label="Apellido"
            v-model="usuario.datoscompletos.apellido"
            placeholder="Apellido"
            readonly
          />
        </vs-col>
        <vs-col>
          <vs-input
            label="Cuit"
            v-model="usuario.datoscompletos.cuit"
            placeholder="Cuit"
            readonly
          />
        </vs-col>
        <div style="float: right">
          <vs-switch v-model="darkMode">
            <template #circle>
              <i v-if="!darkMode" class="bx bxs-moon"></i>
              <i v-else class="bx bxs-sun"></i>
            </template>
          </vs-switch>
        </div>
      </template>
    </vs-card>
  </vs-dialog>
</template>

<script>
export default {
  data() {
    return {
      usuario: this.user,
    };
  },
  props: {
    user: Object,
    active: Boolean,
    darkModeProp: Boolean,
  },
  methods: {},
  computed: {
    darkMode: {
      get() {
        return this.darkModeProp;
      },
      set(value) {
        this.$axios
          .post(`${this.$apiEscritorio}updatedm`, { dm: value })
          .then(
            function (response) {
              if (response.data.estado) {
                document.body.classList.toggle("darken", value);
                document.body.setAttribute("vs-theme", !value ? "" : "dark");
                localStorage.setItem("dm", value ? 1 : 0);
              }
              this.$emit("traerUsuario");
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
              this.$vs.notification({
                title: "Error",
                text: `Error al cambiar el modo del sistema, intente nuevamente.`,
              });
            }.bind(this)
          );
        return value;
      },
    },
    activeLocal: {
      get() {
        return this.active;
      },
      set() {
        return true;
      },
    },
  },
};
</script>

<style>
.perfil-card .vs-card {
  max-width: 600px !important;
}
.perfil-card .vs-card__img {
  max-width: 250px;
  min-height: 300px;
}
.perfil-card .vs-card__img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.perfil-card .vs-card__title {
  margin-bottom: 20px;
}
.perfil-card input {
  cursor: default;
}
.perfil-card .vs-input {
  padding-left: 15px !important;
}
.ticket-tabla {
  padding: 10px;
}
</style>
