import Vue from "vue";
import App from "./App.vue";
import Vuesax from "vuesax";
import VueRouter from "vue-router";
import routes from "./routes/router";
import axios from "axios";
import * as VueWindow from "@hscmap/vue-window";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import VueSocketIO from "vue-socket.io";

import * as utils from "./utils";

import "vuesax/dist/vuesax.css";
import "./css/main.css";
import "boxicons/css/boxicons.min.css";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "@mdi/font/css/materialdesignicons.min.css";

const router = new VueRouter({ routes });

Vue.config.productionTip = false;

const service = axios.create({
  timeout: 20000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 403) {
      location.href = "/";
    }
  }
);

Vue.use(VueRouter);
Vue.use(Vuesax);
Vue.use(VueWindow);
Vue.use(PerfectScrollbar);
Vue.use(
  new VueSocketIO({
    // debug: true,
    // connection: 'http://localhost:5005',
    connection: "https://notificaciones.sim.lacosta.gob.ar",
    options: { secure: true, transports: ["polling"] },
  })
);

Vue.prototype.$axios = service;
Vue.prototype.$utils = utils;
Vue.prototype.$api = "https://backupconsultores.sitechsoftware.com/";
Vue.prototype.$apiEscritorio = "https://backupconsultores.sitechsoftware.com/";
Vue.prototype.$frontend = "https://frontupconsultores.sitechsoftware.com/";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
